import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Represents an email */
  Email: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

/** Autogenerated return type of BuildDocuSignEmbedUsingCode. */
export type BuildDocuSignEmbedUsingCodePayload = {
  __typename?: 'BuildDocuSignEmbedUsingCodePayload';
  url: Scalars['String']['output'];
};

/** Autogenerated return type of BuildDocuSignEmbedUsingLink. */
export type BuildDocuSignEmbedUsingLinkPayload = {
  __typename?: 'BuildDocuSignEmbedUsingLinkPayload';
  url: Scalars['String']['output'];
};

/** Autogenerated return type of BuildSigning. */
export type BuildSigningPayload = {
  __typename?: 'BuildSigningPayload';
  signing: Signing;
};

export type Mutation = {
  __typename?: 'Mutation';
  buildDocuSignEmbedUsingCode: BuildDocuSignEmbedUsingCodePayload;
  buildDocuSignEmbedUsingLink: BuildDocuSignEmbedUsingLinkPayload;
  buildSigning: BuildSigningPayload;
};


export type MutationBuildDocuSignEmbedUsingCodeArgs = {
  code: Scalars['ID']['input'];
};


export type MutationBuildDocuSignEmbedUsingLinkArgs = {
  signedID: Scalars['ID']['input'];
};


export type MutationBuildSigningArgs = {
  code?: InputMaybe<Scalars['Boolean']['input']>;
  input: SigningInput;
};

export type Query = {
  __typename?: 'Query';
  date: Scalars['String']['output'];
};

export type Signer = {
  __typename?: 'Signer';
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SignerInput = {
  email: Scalars['Email']['input'];
  name: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type Signing = {
  __typename?: 'Signing';
  code?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  signers: Array<Signer>;
  url: Scalars['String']['output'];
};

export type SigningInput = {
  callbackURL: Scalars['String']['input'];
  checkboxes?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['Email']['input'];
  name: Scalars['String']['input'];
  radios?: InputMaybe<Scalars['JSON']['input']>;
  signers?: InputMaybe<Array<SignerInput>>;
  templateIDs: Array<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
};

export type BuildDocuSignEmbedUsingCodeMutationVariables = Exact<{
  code: Scalars['ID']['input'];
}>;


export type BuildDocuSignEmbedUsingCodeMutation = { __typename?: 'Mutation', embed: { __typename?: 'BuildDocuSignEmbedUsingCodePayload', url: string } };

export type BuildDocuSignEmbedUsingLinkMutationVariables = Exact<{
  signedID: Scalars['ID']['input'];
}>;


export type BuildDocuSignEmbedUsingLinkMutation = { __typename?: 'Mutation', embed: { __typename?: 'BuildDocuSignEmbedUsingLinkPayload', url: string } };


export const BuildDocuSignEmbedUsingCodeDocument = gql`
    mutation BuildDocuSignEmbedUsingCode($code: ID!) {
  embed: buildDocuSignEmbedUsingCode(code: $code) {
    url
  }
}
    `;

/**
 * __useBuildDocuSignEmbedUsingCodeMutation__
 *
 * To run a mutation, you first call `useBuildDocuSignEmbedUsingCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildDocuSignEmbedUsingCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildDocuSignEmbedUsingCodeMutation, { data, loading, error }] = useBuildDocuSignEmbedUsingCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useBuildDocuSignEmbedUsingCodeMutation(baseOptions?: Apollo.MutationHookOptions<BuildDocuSignEmbedUsingCodeMutation, BuildDocuSignEmbedUsingCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildDocuSignEmbedUsingCodeMutation, BuildDocuSignEmbedUsingCodeMutationVariables>(BuildDocuSignEmbedUsingCodeDocument, options);
      }
export type BuildDocuSignEmbedUsingCodeMutationHookResult = ReturnType<typeof useBuildDocuSignEmbedUsingCodeMutation>;
export type BuildDocuSignEmbedUsingCodeMutationResult = Apollo.MutationResult<BuildDocuSignEmbedUsingCodeMutation>;
export type BuildDocuSignEmbedUsingCodeMutationOptions = Apollo.BaseMutationOptions<BuildDocuSignEmbedUsingCodeMutation, BuildDocuSignEmbedUsingCodeMutationVariables>;
export const BuildDocuSignEmbedUsingLinkDocument = gql`
    mutation BuildDocuSignEmbedUsingLink($signedID: ID!) {
  embed: buildDocuSignEmbedUsingLink(signedID: $signedID) {
    url
  }
}
    `;

/**
 * __useBuildDocuSignEmbedUsingLinkMutation__
 *
 * To run a mutation, you first call `useBuildDocuSignEmbedUsingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildDocuSignEmbedUsingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildDocuSignEmbedUsingLinkMutation, { data, loading, error }] = useBuildDocuSignEmbedUsingLinkMutation({
 *   variables: {
 *      signedID: // value for 'signedID'
 *   },
 * });
 */
export function useBuildDocuSignEmbedUsingLinkMutation(baseOptions?: Apollo.MutationHookOptions<BuildDocuSignEmbedUsingLinkMutation, BuildDocuSignEmbedUsingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildDocuSignEmbedUsingLinkMutation, BuildDocuSignEmbedUsingLinkMutationVariables>(BuildDocuSignEmbedUsingLinkDocument, options);
      }
export type BuildDocuSignEmbedUsingLinkMutationHookResult = ReturnType<typeof useBuildDocuSignEmbedUsingLinkMutation>;
export type BuildDocuSignEmbedUsingLinkMutationResult = Apollo.MutationResult<BuildDocuSignEmbedUsingLinkMutation>;
export type BuildDocuSignEmbedUsingLinkMutationOptions = Apollo.BaseMutationOptions<BuildDocuSignEmbedUsingLinkMutation, BuildDocuSignEmbedUsingLinkMutationVariables>;