import { ApolloProvider } from "@apollo/client";
import { type FC } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { Code } from "./code";
import { Layout } from "./layout";
import { Signing } from "./signing";
import { Thanks } from "./thanks";
import { Whoops } from "./whoops";

import { CLIENT as APOLLO_CLIENT } from "@application/libraries/apollo";

export const App: FC = () => (
  <ApolloProvider client={APOLLO_CLIENT}>
    <HelmetProvider>
      <Layout>
        <Router>
          <Switch>
            <Route exact path="/" component={Code} />
            <Route exact path="/signing/:signedID" component={Signing} />
            <Route exact path="/thanks" component={Thanks} />
            <Route exact path="/whoops" component={Whoops} />
          </Switch>
        </Router>
      </Layout>
    </HelmetProvider>
  </ApolloProvider>
);
