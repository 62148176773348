import styled from "@emotion/styled";
import { type FC } from "react";

import { Input } from "@clutter/clean";

const Digit = styled(Input)`
  font-family: "Basier Square Mono", Monaco, Courier, monospace;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: 12px;
  padding-left: 24px;
  padding-right: 12px;
  text-align: center;
  width: 100%;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  &::-webkit-textfield-decoration-container {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PLACEHOLDER = "•";

export const Digits: FC<{
  length: number;
  disabled?: boolean;
  value: string;
  onChange(value: string): void;
}> = ({ length, disabled, value, onChange }) => (
  <Digit
    autoFocus
    autoComplete="off"
    inputMode="numeric"
    disabled={disabled}
    type="number"
    name="digits"
    placeholder={PLACEHOLDER.repeat(length)}
    value={value}
    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
      onChange(event.target.value);
    }}
  />
);
