import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { type ReactNode, type FC } from "react";

import { Layout as Container, COLORS } from "@clutter/clean";

import LOGO from "@application/assets/logo.svg";

const FONT_CDN = "https://fonts.clutter.com";

const BASIER_SQUARE_MONO_REGULAR = css`
  font-display: swap;
  font-family: "Basier Square Mono";
  src: url("${FONT_CDN}/basiersquaremono-regular.eot");
  src: url("${FONT_CDN}/basiersquaremono-regular.eot?#iefix") format("embedded-opentype"),
    url("${FONT_CDN}/basiersquaremono-regular.woff2") format("woff2"),
    url("${FONT_CDN}/basiersquaremono-regular.woff") format("woff"),
    url("${FONT_CDN}/basiersquaremono-regular.ttf") format("truetype");
`;

const STYLES = css`
  ${BASIER_SQUARE_MONO_REGULAR}
  html {
    background-color: ${COLORS.grayBackground};
    padding: 40px 20px;
  }
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
`;

export const Layout: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <Container>
    <Global styles={STYLES} />
    <Logo alt="Clutter" src={LOGO} />
    {children}
  </Container>
);
