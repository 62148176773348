import { COLORS } from "@clutter/clean";
import styled from "@emotion/styled";

const DISABLED_COLOR = COLORS.hippo;
const DISABLED_BACKGROUND = COLORS.grayBorder;
const HOVER_BACKGROUND = "#066b65";

export const Button = styled.button`
  background: ${COLORS.tealPrimary};
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: ${COLORS.cloud};
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 16px;
  overflow: hidden;
  padding: 20px 52px;
  position: relative;
  text-align: center;
  transition: 0.1s linear;
  transition-property: background, color, padding;
  user-select: none;

  &:disabled {
    pointer-events: none;
    box-shadow: none;
    color: ${DISABLED_COLOR};
    border: none;
    background: ${DISABLED_BACKGROUND};
  }

  &:hover {
    background: ${HOVER_BACKGROUND};
  }
`;
