import styled from "@emotion/styled";
import { Text } from "@clutter/clean";
import { type FC } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import THANKS from "@application/assets/thanks.svg";

const Container = styled.div`
  margin: 32px;
  text-align: center;
`;

const Image = styled.img`
  margin: 32px;
`;

export const Thanks: FC = () => {
  // In the landing rental flow, we await a status on signings in
  // an iframe before allowing a customer to rent a unit.
  // postMessage allows communication between the iframe and the
  // parent in landing.
  useEffect(() => {
    // NOTE: Setting targetOrigin (the second argument) to '*' allows
    // any recipient to listen to messages posted from this source, which
    // is only allowable in this case because the message doesn't carry
    // sensitive information. It is typically a massive security risk.
    parent.postMessage("signed:thanks", "*");
  }, []);

  return (
    <>
      <Helmet>
        <title>Thanks | Clutter</title>
      </Helmet>
      <Container>
        <Text.Title size="medium">Thank you. You can close this page now.</Text.Title>
        <Image src={THANKS} />
        <Link to="/">Reset</Link>
      </Container>
    </>
  );
};
