import { createRoot } from "react-dom/client";

import { App } from "./app";

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("app");
  if (element) {
    const root = createRoot(element);
    root.render(<App />);
  }
});
