import { COLORS } from "@clutter/clean";
import styled from "@emotion/styled";
import { ApolloError } from "@apollo/client";
import { type FC } from "react";

const Alert = styled.p`
  color: ${COLORS.toucan};
  text-align: center;
`;

export const Error: FC<{
  error: ApolloError;
}> = ({ error }) => <Alert>{error.message.replace("GraphQL error: ", "")}</Alert>;
