import * as Sentry from "@sentry/browser";

declare const SENTRY_ENVIRONMENT: string;
declare const SENTRY_DSN: string | null;

if (SENTRY_ENVIRONMENT !== "test" && SENTRY_ENVIRONMENT !== "development" && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
  });
}
