import styled from "@emotion/styled";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type FC } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { type GraphQLError } from "graphql";

import { useBuildDocuSignEmbedUsingLinkMutation } from "@root/app_schema";

import { Error } from "./error";

const Container = styled.div`
  text-align: center;
  margin: 32px;
`;

export const Signing: FC = () => {
  const { signedID } = useParams<{ signedID: string }>();
  const [execute, { loading, error }] = useBuildDocuSignEmbedUsingLinkMutation();

  useEffect(() => {
    const submit = async (): Promise<void> => {
      try {
        const { data } = await execute({ variables: { signedID } });
        const url = data?.embed.url;
        if (url) {
          location.href = url;
        }
      } catch (exception) {
        if ((exception as GraphQLError).message !== `GraphQL error: The link is missing or has expired.`) {
          throw exception;
        }
      }
    };

    submit();
  }, [signedID]);

  return (
    <Container>
      <Helmet>
        <title>Sign | Clutter</title>
      </Helmet>
      {loading && (
        <>
          <FontAwesomeIcon spin icon={faSpinner} /> Verifying
        </>
      )}
      {error && (
        <>
          <Error error={error} />
          <Link to="/">Sign with Code</Link>
        </>
      )}
    </Container>
  );
};
