import styled from "@emotion/styled";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { type FC } from "react";
import { Helmet } from "react-helmet-async";
import { type GraphQLError } from "graphql";

import { useBuildDocuSignEmbedUsingCodeMutation } from "@root/app_schema";

import { Button } from "./button";
import { Digits } from "./digits";
import { Error } from "./error";

const CODE_LENGTH = 6;

const Form = styled.form`
  margin: 32px 0;
  display: block;
`;

const Field = styled.div`
  margin: 0 0 16px;
`;

const Action = styled.div`
  text-align: center;
`;

export const Code: FC = () => {
  const [code, setCode] = useState<string>("");
  const [execute, { loading, error }] = useBuildDocuSignEmbedUsingCodeMutation();

  const submit = async (code: string): Promise<void> => {
    if (code.length === CODE_LENGTH) {
      try {
        const { data } = await execute({ variables: { code } });
        const url = data?.embed.url;
        if (url) {
          location.href = url;
        }
      } catch (exception) {
        if ((exception as GraphQLError).message !== `The code '${code}' does not exist.`) {
          throw error;
        }
      }
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    submit(code);
  };

  return (
    <>
      <Helmet>
        <title>Sign | Clutter</title>
      </Helmet>
      <Form autoComplete="off" onSubmit={onSubmit}>
        <Field>
          <Digits
            length={CODE_LENGTH}
            disabled={loading}
            value={code}
            onChange={async (code: string): Promise<void> => {
              setCode(code);
              submit(code);
            }}
          />
        </Field>
        <Action>
          <Button type="submit" disabled={loading || code.length !== CODE_LENGTH}>
            {loading && <FontAwesomeIcon spin icon={faSpinner} />} {loading ? "Verifying" : "Continue"}
          </Button>
        </Action>
        {error && <Error error={error} />}
      </Form>
    </>
  );
};
